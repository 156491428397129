<template>
	<section class="wrapper">
		<h1 v-if="title" class="auth-title">{{ title }}</h1>
		<div class="body">
			<slot />
		</div>
		<footer class="footer" v-if="login">
			Don't have an account?
			<router-link :to="paths().register">Sign Up</router-link>
		</footer>
		<footer class="footer" v-if="register">
			Have an account?
			<router-link :to="paths().login">Login</router-link>

			<p class="footer__info">
				By registering, you agree to our <a :href="urlTerms" target="_blank">terms</a> and <a :href="urlPrivacy" target="_blank">privacy policy</a>
			</p>
		</footer>
		<footer class="footer" v-if="passwordReset">
			<router-link :to="paths().login">Login</router-link> or <router-link :to="paths().register">Sign Up</router-link>
		</footer>
	</section>
</template>

<script>
	import { mixinPaths } from "../../mixins/mixinPaths";

	export default {
		name: "LayoutAuth",
		props: [
			'login',
			'register',
			'passwordReset',
			'title',
		],
		data() {
			return {
				urlTerms: process.env.VUE_APP_SHOP_TERMS_URL,
				urlPrivacy: process.env.VUE_APP_SHOP_PRIVACY_URL,
			};
		},
		mixins: [
			mixinPaths,
		],
	};
</script>

<style lang="scss" scoped>
	.wrapper {
		margin: 5vh 0;
		padding: 4rem 2rem;
		border-radius: .6rem;
		background-color: $c-gray-primary;

		@include breakpoint(overPhone) {
			margin: 10vh 0;
			@include center-block;
			max-width: 50rem;
		}
	}

	.auth-title {
		color: $c-brand-primary;
		margin-bottom: 4rem;
		font-size: 2.4rem;
		text-align: center;
	}

	.body {
		@include breakpoint(overPhone) {
			padding: 0 10%;
		}
	}

	.footer {
		text-align: center;
		border-top: 1px solid $c-white;
		margin-top: 4rem;
		padding-top: 2rem;
		font-size: 1.2rem;

		a {
			color: $c-brand-primary;
		}

		&__info {
			margin-top: 2rem;
		}
	}
</style>
