<template>
	<Page class="page-login">
		<LayoutAuth
				login="true"
				title="Login"
		>

			<v-form
					ref="form"
					v-model="valid"
					lazy-validation
			>
				<v-text-field
						v-model="email"
						label="Email"
						type="email"
						:rules="[
							validationRules.required,
							validationRules.email,
						]"
						required
						:disabled="auth.login.isLoading"
						color="none"
						@keydown.space.prevent
				></v-text-field>
				<v-text-field
						v-model="password"
						label="Password"
						:rules="[validationRules.required]"
						required
						:disabled="auth.login.isLoading"
						@click:append="showPassword = !showPassword"
						:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
						:type="showPassword ? 'text' : 'password'"
						color="none"
						@keydown.space.prevent
				></v-text-field>
				<div class="password-reset">
					<router-link :to="paths().passwordResetLink">Forgot Password?</router-link>
				</div>
				<v-btn
						type="submit"
						large
						outlined
						:disabled="!valid"
						:loading="auth.login.isLoading"
						@click.prevent="requestLoginHandler"
						class="btn-submit"
				>
					Submit
				</v-btn>
			</v-form>

		</LayoutAuth>
	</Page>
</template>

<script>
	import {
		mapActions,
		mapState
	} from 'vuex';
	import Page from "../components/layouts/LayoutPage";
	import LayoutAuth from "../components/layouts/LayoutAuth";
	import { mixinPaths } from "../mixins/mixinPaths";
	import { mixinValidationRules } from "../mixins/mixinValidationRules";

	export default {
		name: 'Login',
		components: { LayoutAuth, Page },
		mixins: [
			mixinPaths,
			mixinValidationRules,
		],
		data() {
			return {
				valid: false,
				email: '',
				password: '',
				showPassword: false,
			};
		},
		methods: {
			...mapActions( [
				'requestLogin',
			] ),
			requestLoginHandler() {
				if ( this.$refs.form.validate() ) {
					this.requestLogin( {
						email: this.email.toLowerCase(),
						password: this.password,
					} );
				}
			},
		},
		computed: {
			...mapState( [
				'auth'
			] ),
		}
	};
</script>

<style lang="scss" scoped>
	.password-reset {
		display: flex;
		justify-content: flex-end;

		a {
			color: $c-gray-secondary;
			font-size: 1.2rem;
			transition: $transition;

			&:hover {
				color: $c-brand-primary;
			}
		}
	}
</style>
